<template>
  <v-container style="margin-bottom: 100px">
    <customer-info logo="true" dayDateWithBackBtn="true" customer="true" />

    <v-row>
      <v-col md="12">
        <h1>PROJECT BID ITEMS</h1>
      </v-col>
    </v-row>

    <v-data-table
      :headers="headers"
      :items="items"
      class="elevation-1 cursor-pointer"
    >
      <template v-slot:item.actualQty="props">
        <v-edit-dialog :return-value.sync="props.item.actualQty" @save="save">
          {{ props.item.actualQty }}
          <template v-slot:input>
            <v-text-field
              class="hide-details"
              v-model="props.item.actualQty"
              label="Actual Qty"
              single-line
              type="number"
            ></v-text-field>
          </template>
        </v-edit-dialog>
      </template>
    </v-data-table>

    <v-row>
      <v-col>
        <v-checkbox
          v-model="checkbox1"
          label="Scope of work change requires a change in billing."
        ></v-checkbox>
        <v-checkbox
          v-model="checkbox2"
          label="Foreman confirms all items have been completed."
        ></v-checkbox>
        <v-checkbox
          v-model="checkbox3"
          label="I have reviewed these items with the customer."
        ></v-checkbox>
        <v-textarea v-model="comments" outlined label="Comments"></v-textarea>
      </v-col>
    </v-row>

    <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
      {{ snackText }}
      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" text @click="snack = false"> Close </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import CustomerInfo from "./CustomerInfo";

export default {
  name: "TodayBidItems",
  components: { CustomerInfo },
  data() {
    return {
      checkbox1: false,
      checkbox2: false,
      checkbox3: false,
      comments: "",
      snack: false,
      snackColor: "",
      snackText: "",
      headers: [
        { text: "QTY", value: "qty" },
        { text: "Actual Qty", value: "actualQty" },
        { text: "Item", value: "item" },
      ],
      items: [
        {
          qty: "88",
          actualQty: "",
          item: "Exterior Load Redution -Equipment Dig",
        },
        {
          qty: "2",
          actualQty: "",
          item: "Pier - Porch Bracket",
        },
        {
          qty: "1",
          actualQty: "",
          item: "Best 1/3 Primary w/Backup ",
        },
        {
          qty: "1",
          actualQty: "",
          item: "Bell Basin",
        },
        {
          qty: "20",
          actualQty: "",
          item: "Bury Discharge",
        },
        {
          qty: "14",
          actualQty: "",
          item: 'W13 4" Structural Steel I Beam',
        },
      ],
      addItems: {
        qty: "",
        actualQty: "",
        item: "",
      },
    };
  },
  methods: {
    save() {
      this.snack = true;
      this.snackColor = "success";
      this.snackText = "Data saved";
    },
  },
};
</script>

<style scoped>
.v-small-dialog__content {
  padding: 16px !important;
}
.hide-details .v-text-field__details {
  display: none !important;
}
</style>
